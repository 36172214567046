import { css } from '@emotion/react';
import reset from 'emotion-reset';
import { theme } from './theme';

export const GlobalStyle = css`
  ${reset}
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  ::selection {
    background: ${theme.colors.lightGray};
  }

  html {
    font-size: 62.5%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.site.ja};
    font-size: ${theme.font.size.large};
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;

    /* for react modal for mobile*/
    /* &.ReactModal__Body--open {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
    } */

    .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 80ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
      opacity: 1;
    }

    .ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    background-color: ${theme.colors.bg};
    border: 1px solid ${theme.colors.font};
    line-height: 1.6;
    font-family: inherit;
    font-size: inherit;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover,
    &.focus-visible {
      text-decoration: none;
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &.focus-visible {
      outline: 1px solid black;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 1em;
  }
`;

export default GlobalStyle;
