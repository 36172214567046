import * as React from 'react';
import { Sharp } from './sharp';
import type { Props as SharpProps } from './sharp';

export type Props = SharpProps & {
  localFile: {
    publicURL?: string;
    extension?: string;
  };
};

export const Img: React.VFC<Props> = (props: Props) => {
  const { localFile, alt, className, loading } = props;
  if (!localFile) {
    return null;
  }
  if (localFile.extension === 'gif' || localFile.extension === 'svg') {
    return (
      <div className={`${className ?? ''} image-type-${localFile.extension}`}>
        <img src={localFile.publicURL} alt={alt ?? ''} loading={loading} />
      </div>
    );
  } else {
    return <Sharp {...props} />;
  }
};

export default Img;
