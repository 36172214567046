import * as React from 'react';
import * as THREE from 'three';
import { useLoader, useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

type Props = {
  className?: string;
  env: string;
  src: string;
  position?: THREE.Vector3;
  scale?: THREE.Vector3;
};

const useEnvMap = (env: string) => {
  const { scene, gl } = useThree();
  const texture = useLoader(RGBELoader, env);
  React.useEffect(() => {
    const pmremGenerator = new THREE.PMREMGenerator(gl);
    pmremGenerator.compileEquirectangularShader();
    const envMap = pmremGenerator.fromEquirectangular(texture).texture;
    scene.environment = envMap;
    texture.dispose();
    pmremGenerator.dispose();
  }, [gl, scene, texture]);
};

export const Model: React.VFC<Props> = ({
  className,
  env,
  src,
  position,
  scale,
}) => {
  useEnvMap(env);
  const gltf = useGLTF(src);
  return (
    <mesh position={position} scale={scale}>
      <primitive object={gltf.scene} />
    </mesh>
  );
};

export default Model;
