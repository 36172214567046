import React from 'react';

export const initialValue = {
  isToggle: false,
};

type MenuToggleContextType = typeof initialValue;

export const MenuToggleContext = React.createContext<MenuToggleContextType>(
  initialValue
);

type ReducerType = { type: string; payload: MenuToggleContextType };

type DispatchContextType = {
  dispatch: React.Dispatch<ReducerType> | null;
};

export const MenuToggleDispatchContext = React.createContext<DispatchContextType>(
  {
    dispatch: null,
  }
);

export const MenuToggleReducer: React.Reducer<
  MenuToggleContextType,
  ReducerType
> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('MenuToggleReducer error');
  }
};

export default MenuToggleContext;
