import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { use100vh } from 'react-div-100vh';
import { useIsDatabasePage, useIsMounted } from 'src/utils';
import { SiteHeader } from 'src/components/common/site-header';
import { Miro } from 'src/components/common/miro';
import { Loader } from 'src/components/common/loader';

type Props = {
  className?: string;
  pathname: string;
  children: React.ReactNode;
};

export const SitePage: React.FC<Props> = ({
  className,
  pathname,
  children,
}: Props) => {
  const isMounted = useIsMounted();
  const isDatabasePage = useIsDatabasePage(pathname);
  const hasMiro = isMounted && !isDatabasePage;
  const custom100vh = use100vh();
  return (
    <Wrapper className={`${className ?? ''}`}>
      {!isDatabasePage && <StyledLoader />}
      <StyledSiteHeader />
      <Main
        role="main"
        id="main"
        className={`${isMounted ? 'is-mounted' : ''} ${
          hasMiro ? 'has-miro' : ''
        }`}
      >
        <MainContent id="main-content" className={hasMiro ? 'has-miro' : ''}>
          {children}
        </MainContent>
        <StyledMiro
          className={hasMiro ? 'has-miro' : ''}
          custom100vh={custom100vh ? `${custom100vh}px` : '100vh'}
        />
      </Main>
    </Wrapper>
  );
};

const StyledLoader = styled(Loader)`
  ${media.siteBreakpointOrLess} {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: white;
  }
  ${media.greaterThanSiteBreakpoint} {
    display: none;
  }
`;

const StyledSiteHeader = styled(SiteHeader)`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  ${media.siteBreakpointOrLess} {
    width: 100%;
  }
  ${media.greaterThanSiteBreakpoint} {
    width: ${({ theme }) => theme.structure.header.width.pc}px;
  }
`;

const StyledMiro = styled(Miro)<{ custom100vh: string }>`
  display: none;
  &.has-miro {
    display: block;
  }
  ${media.siteBreakpointOrLess} {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    margin-top: ${({ theme }) => theme.structure.title.height.sp}px;
    height: calc(
      ${({ custom100vh }) => custom100vh} -
        ${({ theme }) => theme.structure.title.height.sp}px -
        ${({ theme }) => theme.structure.menu.height.sp}px -
        ${({ theme }) => theme.structure.mobileDeadZone.bottom}px
    );
  }
  ${media.greaterThanSiteBreakpoint} {
    width: 100%;
    position: sticky;
    top: ${({ theme }) => theme.structure.site.margin.top.pc}px;
    height: calc(
      ${({ custom100vh }) => custom100vh} -
        ${({ theme }) => theme.structure.site.margin.top.pc * 2}px
    );
  }
`;

const MainContent = styled.div`
  ${media.greaterThanSiteBreakpoint} {
  }
  &.has-miro {
    ${media.siteBreakpointOrLess} {
      margin-top: 23px;
    }
    ${media.greaterThanSiteBreakpoint} {
      padding-top: ${({ theme }) => theme.structure.header.height.pc}px;
      padding-left: ${({ theme }) => theme.structure.site.margin.left.pc}px;
    }
  }
`;

const Main = styled.main`
  ${media.greaterThanSiteBreakpoint} {
    flex-grow: 1;
    opacity: 0;
    &.is-mounted {
      opacity: 1;
    }
  }
  &.has-miro {
    ${media.siteBreakpointOrLess} {
      display: flex;
      flex-direction: column-reverse;
    }
    ${media.greaterThanSiteBreakpoint} {
      display: grid;
      grid-template-columns: ${({ theme }) => theme.structure.header.width.pc}px 1fr;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: ${({ theme }) => theme.font.size.xLarge};
  line-height: ${({ theme }) => theme.lineHeight.en};
  padding-left: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
  padding-right: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
  ${media.greaterThanSiteBreakpoint} {
    font-size: ${({ theme }) => theme.font.size.large};
    padding-left: 0;
    padding-right: 0;
    padding-top: ${({ theme }) => theme.structure.site.margin.top.pc}px;
    padding-bottom: ${({ theme }) => theme.structure.site.margin.bottom.pc}px;
  }
`;

export default SitePage;
