import * as React from 'react';
import styled from '@emotion/styled';
import { MainEntry } from 'src/components/common';
import { DatabaseSingleType } from 'types/custom-types';

type Props = DatabaseSingleType & {
  className?: string;
};

export const Text: React.VFC<Props> = ({
  className,
  revTagId,
  location,
  elementEn,
  period,
  materialEn,
  sizeW,
  sizeH,
  sizeD,
  now,
  nextState,
}) => (
  <Wrapper className={className ?? ''}>
    <MainEntry title={revTagId} hideTitle={true}>
      <dl className="english">
        <dt>&lt;Tag ID&gt;</dt>
        <dd>{revTagId}</dd>
        <dt>&lt;Location&gt;</dt>
        <dd>{location}</dd>
        <dt>&lt;Element&gt;</dt>
        <dd>{elementEn}</dd>
        <dt>&lt;Period&gt;</dt>
        <dd>{period}</dd>
        <dt>&lt;Material&gt;</dt>
        <dd>{materialEn}</dd>
        <dt>&lt;Size W&gt;</dt>
        <dd>{sizeW}</dd>
        <dt>&lt;Size H&gt;</dt>
        <dd>{sizeH}</dd>
        <dt>&lt;Size D&gt;</dt>
        <dd>{sizeD}</dd>
        <dt>&lt;Now&gt;</dt>
        <dd>{now}</dd>
        <dt>&lt;Next State&gt;</dt>
        <dd>{nextState}</dd>
      </dl>
    </MainEntry>
  </Wrapper>
);

const Wrapper = styled.div``;

export default Text;
