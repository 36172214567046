import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { Item, Props as ItemProps } from './item';

type Props = {
  className?: string;
  menus: ItemProps[];
};

export const MenuList: React.VFC<Props> = ({ className, menus }: Props) => (
  <Wrapper className={className ?? ''}>
    {menus.map((menu, index) => (
      <React.Fragment key={`header-menu-${index}`}>
        <StyledItem {...menu} />
      </React.Fragment>
    ))}
  </Wrapper>
);

const StyledItem = styled(Item)`
  line-height: 1.4;
  transform-origin: left;
  transform: scaleX(0.9);
  ${media.greaterThanSiteBreakpoint} {
    line-height: 1.2;
  }
`;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
`;

export default MenuList;
