import React from 'react';

export const initialValue = {
  isHidden: false,
};

type InitialLoaderContextType = typeof initialValue;

export const InitialLoaderContext = React.createContext<InitialLoaderContextType>(
  initialValue
);

type ReducerType = { type: string; payload: InitialLoaderContextType };

type DispatchContextType = {
  dispatch: React.Dispatch<ReducerType> | null;
};

export const InitialLoaderDispatchContext = React.createContext<DispatchContextType>(
  {
    dispatch: null,
  }
);

export const InitialLoaderReducer: React.Reducer<
  InitialLoaderContextType,
  ReducerType
> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('InitialLoaderReducer error');
  }
};

export default InitialLoaderContext;
