import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEO, Entry } from 'src/components/information';
import { PageContextType } from 'types/custom-types';

type Props = PageProps<GatsbyTypes.GetInformationQuery, PageContextType>;

export const query = graphql`
  query GetInformation {
    seo: markdownRemark(frontmatter: { uid: { eq: "information" } }) {
      ...InformationSingleSeo
    }
    markdownRemark(frontmatter: { uid: { eq: "information" } }) {
      ...InformationEntry
    }
  }
`;

export const Information: React.VFC<Props> = ({
  data: { seo, markdownRemark },
}: Props) => (
  <>
    {seo && <SEO {...seo} />}
    {markdownRemark && <Entry {...markdownRemark} />}
  </>
);

export default Information;
