import * as React from 'react';
import { Link } from 'react-scroll';
import { theme } from 'src/styles';
import { useIsMobile } from 'src/utils';

type Props = {
  className?: string;
  children: React.ReactNode;
  to: string;
  ['aria-label']?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const ScrollAnchor: React.FC<Props> = ({
  className,
  children,
  to,
  'aria-label': ariaLabel,
  onClick,
}) => {
  const { scrollOffset } = theme;
  const isMobile = useIsMobile();
  const withoutHashTo = to.substring(1);
  return (
    <Link
      className={className ?? ''}
      to={withoutHashTo}
      aria-label={ariaLabel}
      smooth={true}
      duration={300}
      onClick={(onClick as () => void) ?? undefined}
      offset={
        scrollOffset
          ? isMobile
            ? -scrollOffset.sp
            : -scrollOffset.pc
          : undefined
      }
    >
      {children}
    </Link>
  );
};

export default ScrollAnchor;
