import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { usePathname, useIsDatabasePage, useIsMounted } from 'src/utils';
import { Title } from './title';
import { Navigation } from './navigation';
import { NavigationMobile } from './navigation-mobile';

type Props = {
  className?: string;
};

const useIsShownNavigation = (pathname: string) => {
  const isMounted = useIsMounted();
  const isDatabasePage = useIsDatabasePage(pathname);
  return isMounted && !isDatabasePage;
};

export const SiteHeader: React.VFC<Props> = ({ className }: Props) => {
  const { pathname } = usePathname();
  const isShownNavigation = useIsShownNavigation(pathname);
  return (
    <Wrapper className={`${className ?? ''}`} role="banner">
      <TitleWrapper>
        <StyledTitle />
      </TitleWrapper>
      <StyledNavigationMobile
        className={`${isShownNavigation ? 'is-shown' : ''}`}
      />
      <StyledNavigation className={`${isShownNavigation ? 'is-shown' : ''}`} />
    </Wrapper>
  );
};

const StyledTitle = styled(Title)`
  ${media.siteBreakpointOrLess} {
    max-width: 246px;
    margin-left: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
  }
  ${media.greaterThanSiteBreakpoint} {
    width: 276px;
    height: 171px;
  }
`;

const TitleWrapper = styled.div`
  ${media.siteBreakpointOrLess} {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${({ theme }) => theme.structure.title.height.sp}px;
  }
`;

const StyledNavigationMobile = styled(NavigationMobile)`
  display: none;
  ${media.siteBreakpointOrLess} {
    &.is-shown {
      display: block;
    }
  }
`;

const StyledNavigation = styled(Navigation)`
  display: none;
  ${media.greaterThanSiteBreakpoint} {
    margin-top: 16px;
    margin-left: 230px;
    &.is-shown {
      display: block;
    }
  }
`;

const Wrapper = styled.header`
  ${media.greaterThanSiteBreakpoint} {
    pointer-events: none;
    padding-top: ${({ theme }) => theme.structure.site.margin.top.pc + 5}px;
    padding-left: ${({ theme }) => theme.structure.site.margin.left.pc}px;
  }
`;

export default SiteHeader;
