import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { MainEntry } from 'src/components/common';

type Props = GatsbyTypes.InformationEntryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment InformationEntry on MarkdownRemark {
    frontmatter {
      title
      sponsors {
        image {
          publicURL
        }
        alt
      }
    }
    html
  }
`;

export const Entry: React.VFC<Props> = ({
  className,
  frontmatter,
  html,
}: Props) => (
  <MainEntry className={className ?? ''} title={frontmatter?.title}>
    {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    {frontmatter?.sponsors && (
      <ImageList>
        {frontmatter.sponsors.map((sponsor, index) => (
          <ImageListItem key={`sponsor-${index}`}>
            <img src={sponsor?.image?.publicURL} alt={sponsor?.alt} />
          </ImageListItem>
        ))}
      </ImageList>
    )}
  </MainEntry>
);

const ImageListItem = styled.li`
  margin-top: 0;
  &:nth-of-type(1),
  &:nth-of-type(2) {
    grid-column: 1 / 3;
  }
  ${media.lessThanSiteBreakpoint} {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      img {
        width: 70%;
      }
    }
  }
  ${media.siteBreakpointOrMore} {
    &:nth-of-type(3) {
      grid-column-start: 1;
    }
  }
`;

const ImageList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${media.siteBreakpointOrMore} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default Entry;
