import * as React from 'react';
import { PageProps } from 'gatsby';
import { SEO } from 'src/components/common';
import { PageContextType } from 'types/custom-types';

type Props = PageProps<unknown, PageContextType>;

export const Error404: React.FC<Props> = () => (
  <>
    <SEO title="404 page not found" />
    <p>404 page not found.</p>
  </>
);

export default Error404;
