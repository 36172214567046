import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  url: string;
};

// https://www.youtube.com/watch?v=iUGnvvK1so8&list=PLMUpW_0m9hNC_eGDk9rQmkgsunOh-24od&index=4&t=82s
const useSrc = (url: string) =>
  React.useMemo(() => {
    const parsedUrl = new URL(url);
    const id = parsedUrl.searchParams.get('v');
    const time = parsedUrl.searchParams.get('t');
    // const queryObj: { [key: string]: string } = [
    //   ...new URLSearchParams(parsedUrl.search).entries(),
    // ].reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
    if (id) {
      let src = `https://www.youtube.com/embed/${id}?rel=0&playsinline=1`;
      if (time) {
        src += `&t={time}`;
      }
      // src += Object.entries(queryObj)
      //   .filter(([key, _]) => key !== 'v')
      //   .map(([key, value]) => `&${key}=${value}`)
      //   .join('=');
      // if ('list' in queryObj) {
      //   src += `&listType=playlist`;
      // }
      // src += '&origin=https://www.vba2020.jp';
      return src;
    }
    return null;
  }, [url]);

export const Youtube: React.VFC<Props> = ({ className, url }) => {
  const src = useSrc(url);
  if (!src) {
    return null;
  }
  return (
    <Wrapper className={className ?? ''}>
      <Iframe
        width="560"
        height="315"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  @supports (aspect-ratio: 560 / 315) {
    aspect-ratio: 560 / 315;
  }
  @supports not (aspect-ratio: 560 / 315) {
    position: absolute;
  }
`;

const Wrapper = styled.div`
  position: relative;
  @supports not (aspect-ratio: 560 / 315) {
    padding-bottom: ${(315 / 560) * 100}%;
  }
`;

export default Youtube;
