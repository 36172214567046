import * as React from 'react';
import styled from '@emotion/styled';
import { MOBILE_DEFAULT_MENUS } from 'src/constants';
import {
  MenuToggleContext,
  MenuToggleDispatchContext,
} from 'src/contexts/menu-toggle-context';
import { ScreenReaderTextStyle } from 'src/components/common/screen-reader-text';
import { Button } from 'src/components/common/atoms';
import { MenuList } from '../menu-list';
import { usePathname } from 'src/utils';

type Props = {
  className?: string;
};

const useHideMenuAfterPageTransition = () => {
  const { pathname } = usePathname();
  const { dispatch } = React.useContext(MenuToggleDispatchContext);
  React.useEffect(() => {
    dispatch && dispatch({ type: 'set', payload: { isToggle: false } });
  }, [dispatch, pathname]);
};

const useIsShownMenu = () => {
  const { isToggle } = React.useContext(MenuToggleContext);
  const { dispatch } = React.useContext(MenuToggleDispatchContext);
  const onClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    dispatch && dispatch({ type: 'set', payload: { isToggle: !isToggle } });
  }, [dispatch, isToggle]);
  return { isShownMenu: isToggle, onClick };
};

export const NavigationMobile: React.VFC<Props> = ({ className }: Props) => {
  useHideMenuAfterPageTransition();
  const { isShownMenu, onClick } = useIsShownMenu();
  return (
    <Wrapper role="navigation" className={className ?? ''}>
      <StyledButton onClick={onClick}>
        {isShownMenu ? 'close' : 'menu'}
      </StyledButton>
      <StyledMenuList
        className={isShownMenu ? 'is-shown' : ''}
        menus={MOBILE_DEFAULT_MENUS}
      />
    </Wrapper>
  );
};

const StyledButton = styled(Button)`
  pointer-events: auto;
  position: fixed;
  z-index: 1000;
  right: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
  bottom: ${({ theme }) => theme.structure.mobileDeadZone.bottom}px;
`;

const StyledMenuList = styled(MenuList)`
  opacity: 0;
  &:not(.is-shown) {
    ${ScreenReaderTextStyle};
  }
  &.is-shown {
    opacity: 1;
    position: fixed;
    /* z-index: */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: white;
    padding-top: 15px;
    padding-left: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
    padding-right: ${({ theme }) =>
      theme.structure.site.margin.horizontal.sp}px;
  }
  > li:nth-of-type(2) {
    margin-top: 25px;
  }
`;

const Wrapper = styled.nav`
  font-family: ${({ theme }) => theme.font.family.site.ja};
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  color: ${({ theme }) => theme.colors.blue};
`;

export default NavigationMobile;
