import * as React from 'react';
import * as THREE from 'three';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { Model } from './model';
import type { Props as CanvasProps } from '@react-three/fiber';

type Props = {
  className?: string;
  slug: string;
};

const SCALE_VALUE = 0.002 as number;
const SCALE = new THREE.Vector3(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);
const POSITION = new THREE.Vector3(0, 0, 0);
const ENV_PATH = '/3d/env/royal_esplanade_1k.hdr';

const useHandleCreated = () =>
  React.useCallback<NonNullable<CanvasProps['onCreated']>>(({ gl }) => {
    gl.setClearColor(new THREE.Color('#dddddd'));
    // NOTE:
    // 以下はCanvasのcolorManagementと同じ
    // gl.toneMapping = THREE.ACESFilmicToneMapping;
    // gl.toneMappingExposure = 1;
    // gl.outputEncoding = THREE.sRGBEncoding;
    // NOTE:
    // 以下はCanvasのshadowMapと同じ
    // gl.shadowMap.enabled = true;
  }, []);

const use3d = (slug: string) => {
  const [src, setSrc] = React.useState<string | null>(null);
  // import(`src/images/database/3d/${slug}/scene.gltf`)
  import(`/static/3d/model/${slug}/scene.gltf`)
    .then((data) => {
      // NOTE:
      // data.defaultを直接渡すと、
      // scene.gltfからscene.binを読み込む際の、
      // 相対パスの位置がズレるので、
      // 読み込ませるpathは直接指定する。
      // ここでのimportはあくまで、fileの有無のチェックのみ行う
      setSrc(`/3d/model/${slug}/scene.gltf`);
    })
    .catch((error) => {
      if (error.code !== 'MODULE_NOT_FOUND') {
        console.log(error);
      }
    });
  return src;
};

export const ThreeD: React.VFC<Props> = ({ className, slug }) => {
  const handleCreated = useHandleCreated();
  const src = use3d(slug);
  // const src = `/3d/${slug}/scene.gltf`;
  if (!src) {
    return null;
  }
  return (
    <Wrapper className={className ?? ''}>
      <CanvasWrapper>
        <Canvas
          camera={{
            fov: 45,
            // aspect: window.innerWidth / window.innerHeight,
            aspect: 1 / 1,
            near: 0.25,
            far: 20,
            position: [-1.8, 0.6, 2.7],
          }}
          // pixelRatio={window ? window?.devicePixelRatio : undefined}
          onCreated={handleCreated}
          // invalidateFrameloop={true}
          mode={'concurrent'}
          // colorManagement={true}
          // shadowMap={true}
        >
          <OrbitControls
            minDistance={0.5}
            maxDistance={10}
            enableDamping={false}
          />
          <React.Suspense
            fallback={
              <Html center>
                <LoadingText>
                  now loading
                  <Point aria-hidden="true">.</Point>
                  <Point aria-hidden="true">.</Point>
                  <Point aria-hidden="true">.</Point>
                  <Point aria-hidden="true">.</Point>
                </LoadingText>
              </Html>
            }
          >
            <Model position={POSITION} scale={SCALE} env={ENV_PATH} src={src} />
          </React.Suspense>
        </Canvas>
      </CanvasWrapper>
    </Wrapper>
  );
};

const loading1 = keyframes`
  0%, 19% {
    opacity: 0;
  }
  20%, 100% {
    opacity: 1;
  }
`;

const loading2 = keyframes`
  0%, 39% {
    opacity: 0;
  }
  40%, 100% {
    opacity: 1;
  }
`;

const loading3 = keyframes`
  0%, 59% {
    opacity: 0;
  }
  60%, 100% {
    opacity: 1;
  }
`;

const loading4 = keyframes`
  0%, 79% {
    opacity: 0;
  }
  80%, 100% {
    opacity: 1;
  }
`;

const Point = styled.span`
  animation: 800ms infinite;
  &:nth-of-type(1) {
    animation-name: ${loading1};
  }
  &:nth-of-type(2) {
    animation-name: ${loading2};
  }
  &:nth-of-type(3) {
    animation-name: ${loading3};
  }
  &:nth-of-type(4) {
    animation-name: ${loading4};
  }
`;

const LoadingText = styled.p`
  width: 180px;
  text-align: center;
  /* NOTE:
  Canvas無いなので、
  themeは使えない
   */
  color: #0000ff;
  font-family: 'acumin-pro';
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  canvas {
    max-width: 100%;
  }
  @supports not (aspect-ratio: 3 / 2) {
    position: absolute;
  }
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  @supports (aspect-ratio: 3 / 2) {
    aspect-ratio: 3 / 2;
  }
  @supports not (aspect-ratio: 3 / 2) {
    padding-bottom: ${(2 / 3) * 100}%;
  }
`;

export default ThreeD;
