import * as React from 'react';
import styled from '@emotion/styled';
import { scroller } from 'react-scroll';
import { useIsMobile } from 'src/utils';
import { Anchor } from 'src/components/common/atoms';
import type { MenuType } from 'types/custom-types';

export type Props = MenuType & {
  className?: string;
};

const useOnClick = () => {
  const isMobile = useIsMobile();
  return React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      if (isMobile) {
        const pathname = event.currentTarget.pathname;
        let id: NodeJS.Timeout;
        if (pathname === '/overview/' || pathname === '/information/') {
          id = setTimeout(() => {
            scroller.scrollTo('main-content', {
              duration: 250,
              offset: -65,
              smooth: 'easeOutQuint',
            });
          }, 600);
          return () => {
            clearTimeout(id);
          };
        }
      }
    },
    [isMobile]
  );
};

export const Item: React.FC<Props> = ({ className, label, to }: Props) => {
  const onClick = useOnClick();
  if (!to) {
    return null;
  }
  return (
    <Wrapper className={className ?? ''}>
      <StyledAnchor
        to={to}
        activeClassName={'current'}
        partiallyActive={true}
        onClick={onClick}
      >
        {label}
      </StyledAnchor>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  pointer-events: auto;
  text-decoration: none;
  position: relative;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`;

const Wrapper = styled.li`
  line-height: 1;
`;

export default Item;
