import * as React from 'react';
import { PageProps } from 'gatsby';
import { SEO } from 'src/components/common';
import { PageContextType } from 'types/custom-types';

type Props = PageProps<unknown, PageContextType>;

export const IndexPage: React.VFC<Props> = () => (
  <>
    <SEO />
  </>
);

export default IndexPage;
