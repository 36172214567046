import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEO, Entry } from 'src/components/overview';
import { PageContextType } from 'types/custom-types';

type Props = PageProps<GatsbyTypes.GetOverviewQuery, PageContextType>;

export const query = graphql`
  query GetOverview {
    seo: markdownRemark(frontmatter: { uid: { eq: "overview" } }) {
      ...OverviewSingleSeo
    }
    markdownRemark(frontmatter: { uid: { eq: "overview" } }) {
      ...OverviewEntry
    }
  }
`;

export const Overview: React.VFC<Props> = ({
  data: { seo, markdownRemark },
}: Props) => (
  <>
    {seo && <SEO {...seo} />}
    {markdownRemark && <Entry {...markdownRemark} />}
  </>
);

export default Overview;
