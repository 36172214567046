import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { usePathname, useIsDatabasePage } from 'src/utils';
import { ScreenReaderText } from 'src/components/common';
import { Languages } from 'src/components/common/languages';

type Props = {
  className?: string;
  title?: string;
  hideTitle?: boolean;
  children?: React.ReactNode;
};

const useShowLanguages = () => {
  const { pathname } = usePathname();
  const isDatabasePage = useIsDatabasePage(pathname);
  return React.useMemo(() => !isDatabasePage, [isDatabasePage]);
};

export const MainEntry: React.FC<Props> = ({
  className,
  title,
  hideTitle,
  children,
}) => {
  const showLanguages = useShowLanguages();
  return (
    <Wrapper className={`${className ?? ''}`}>
      {showLanguages && <StyledLanguages />}
      {title && (
        <Title>
          {!hideTitle && title}
          {hideTitle && <ScreenReaderText>title</ScreenReaderText>}
        </Title>
      )}
      {children && (
        <Content className={hideTitle ? 'hide-title' : ''}>{children}</Content>
      )}
    </Wrapper>
  );
};

const StyledLanguages = styled(Languages)``;

const Title = styled.h1`
  ${StyledLanguages} + & {
    margin-top: 1em;
  }
`;

const Content = styled.div`
  padding-bottom: ${({ theme }) => theme.structure.entry.margin.bottom.sp}px;
  ${media.greaterThanSiteBreakpoint} {
    padding-right: ${({ theme }) => theme.structure.entry.margin.right.pc}px;
    padding-bottom: ${({ theme }) => theme.structure.entry.margin.bottom.pc}px;
  }

  &:not(.hide-title) {
    margin-top: 1.55em;
  }

  *:not(h2):not(li):not(dt) + * {
    margin-top: 1.8em;
  }

  font-family: ${({ theme }) => theme.font.family.site.ja};
  .english {
    font-family: ${({ theme }) => theme.font.family.site.en};
    line-height: ${({ theme }) => theme.lineHeight.en};
    font-size: ${({ theme }) => theme.font.size.xLarge};
    ${media.greaterThanSiteBreakpoint} {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }
  .japanese {
    margin-top: 2.4em;
    line-height: ${({ theme }) => theme.lineHeight.ja};
    font-size: ${({ theme }) => theme.font.size.medium};
    ${media.greaterThanSiteBreakpoint} {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  em {
    font-style: italic;
  }
`;

const Wrapper = styled.div``;

export default MainEntry;
