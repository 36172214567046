import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { Text } from './text';
import { Product } from './product';
import { ThreeD } from './three-d';
import { AxonometricDrawing } from './axonometric-drawing';
import { Youtube } from './youtube';
import type { DatabaseSingleType } from 'types/custom-types';

type Props = DatabaseSingleType & {
  className?: string;
  product: GatsbyTypes.GetDatabaseSingleQuery['product'];
  axonometricDrawing: GatsbyTypes.GetDatabaseSingleQuery['axonometricDrawing'];
};

export const Entry: React.FC<Props> = ({
  className,
  product,
  axonometricDrawing,
  youtube,
  ...databaseProps
}) => {
  const { revTagId } = databaseProps;
  return (
    <Wrapper className={className ?? ''}>
      <StyledText {...databaseProps} />
      <Images>
        {product && <Product {...product} />}
        {revTagId && <ThreeD slug={revTagId} />}
        {axonometricDrawing && <AxonometricDrawing {...axonometricDrawing} />}
        {youtube && <Youtube url={youtube} />}
      </Images>
    </Wrapper>
  );
};

const StyledText = styled(Text)`
  ${media.siteBreakpointOrLess} {
    margin-top: 23px;
  }
  ${media.greaterThanSiteBreakpoint} {
    padding-top: ${({ theme }) => theme.structure.header.height.pc}px;
    padding-left: ${({ theme }) => theme.structure.site.margin.left.pc}px;
  }
`;

const Images = styled.div`
  ${media.siteBreakpointOrLess} {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    margin-top: ${({ theme }) => theme.structure.title.height.sp}px;
    > * + * {
      margin-top: 8px;
    }
  }
  ${media.greaterThanSiteBreakpoint} {
    > * + * {
      margin-top: 20px;
    }
  }
`;

const Wrapper = styled.div`
  ${media.siteBreakpointOrLess} {
    display: flex;
    flex-direction: column-reverse;
  }
  ${media.greaterThanSiteBreakpoint} {
    display: grid;
    grid-template-columns: ${({ theme }) => theme.structure.header.width.pc}px 1fr;
  }
`;

export default Entry;
