import React from 'react';

export const initialValue = {
  pathname: '',
  isHome: true,
};

type PathnameContextType = typeof initialValue;

export const PathnameContext = React.createContext<PathnameContextType>(
  initialValue
);

export default PathnameContext;
