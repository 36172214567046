import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { MIRO_BOARD_ID } from 'src/constants';

type Props = {
  className?: string;
};

export const Miro: React.VFC<Props> = ({ className }) => (
  <Wrapper className={className ?? ''}>
    <StyledIframe
      title="miro embed"
      width="768"
      height="432"
      // src={`https://miro.com/app/embed/${MIRO_BOARD_ID}/?autoplay=yep&pres=1&frameId=3074457358980435170`}
      src={`https://miro.com/app/live-embed/${MIRO_BOARD_ID}/?embedAutoplay=true&moveToViewport=-3000,-66600,19000,3000`}
      frameBorder="0"
      scrolling="no"
    />
  </Wrapper>
);

const StyledIframe = styled.iframe`
  --margin-top: 100px;
  --margin-left: 70px;
  --margin-bottom: 219px;
  width: 100%;
  height: 100%;
  width: calc(100% + var(--margin-left));
  margin-left: calc(var(--margin-left) * -1);
  height: calc(100% + var(--margin-top) + var(--margin-bottom));
  margin-top: calc(var(--margin-top) * -1);
  margin-bottom: calc(var(--margin-bottom) * -1);
  ${media.greaterThanSiteBreakpoint} {
    --margin-top: 64px;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 1px white;
    pointer-events: none;
  }
`;

export default Miro;
