import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link, GatsbyLinkProps } from 'gatsby';
import { ScrollAnchor } from './scroll-anchor';

type Props = GatsbyLinkProps<unknown> & {
  className?: string;
  children: React.ReactNode;
};

export const Anchor: React.FC<Props> = ({
  className,
  to,
  children,
  ['aria-label']: ariaLabel,
  activeClassName = 'current',
  partiallyActive = false,
  onClick,
}: Props) => {
  const isInternal = to?.startsWith('/');
  const isHash = to?.startsWith('#');
  if (isInternal) {
    return (
      <StyledLink
        className={className || ''}
        to={to?.endsWith('/') ? to : `${to}/`}
        aria-label={ariaLabel || ''}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        onClick={onClick ?? undefined}
      >
        {children}
      </StyledLink>
    );
  } else if (isHash) {
    return (
      <StyledScrollAnchor
        className={className || ''}
        to={to}
        aria-label={ariaLabel || ''}
        onClick={onClick ?? undefined}
      >
        {children}
      </StyledScrollAnchor>
    );
  } else {
    return (
      <StyledA
        className={className || ''}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel || ''}
      >
        {children}
      </StyledA>
    );
  }
};

const BaseStyle = css`
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  ${BaseStyle}
`;

const StyledScrollAnchor = styled(ScrollAnchor)`
  ${BaseStyle}
`;

const StyledA = styled.a`
  ${BaseStyle}
`;

export default Anchor;
