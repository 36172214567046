import * as React from 'react';
import { graphql } from 'gatsby';
import { MainEntry } from 'src/components/common';

type Props = GatsbyTypes.OverviewEntryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment OverviewEntry on MarkdownRemark {
    frontmatter {
      title
    }
    html
  }
`;

export const Entry: React.FC<Props> = ({
  className,
  frontmatter,
  html,
}: Props) => (
  <MainEntry className={className ?? ''} title={frontmatter?.title}>
    {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
  </MainEntry>
);

export default Entry;
