import * as React from 'react';
import { graphql } from 'gatsby';
import { Img } from 'src/components/common/atoms';

type Props = GatsbyTypes.DatabaseSingleProductFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment DatabaseSingleProduct on File {
    publicURL
    extension
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, quality: 85)
    }
  }
`;

export const Product: React.VFC<Props> = ({
  className,
  childImageSharp,
  publicURL,
  extension,
}) => (
  <div className={className ?? ''}>
    <Img
      localFile={{ childImageSharp, publicURL, extension }}
      alt="product image"
    />
  </div>
);

export default Product;
