import * as React from 'react';
import { PageProps } from 'gatsby';
import { Global } from '@emotion/react';
import { GlobalStyle } from 'src/styles';
import { ProviderManager, SitePage } from 'src/components/common';
import { PageContextType } from 'types/custom-types';

type Props = PageProps<unknown, PageContextType>;

const Layouts: React.FC<Props> = ({ location, children }: Props) => (
  <>
    <Global styles={GlobalStyle} />
    <ProviderManager pathname={location.pathname}>
      <SitePage pathname={location.pathname}>{children}</SitePage>
    </ProviderManager>
  </>
);

export default Layouts;
