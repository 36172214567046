import * as React from 'react';
import { ThemeProvider } from '@emotion/react';
import { theme as styleTheme } from 'src/styles';
import { PathnameContext } from 'src/contexts/pathname-context';
import {
  MenuToggleContext,
  MenuToggleDispatchContext,
  MenuToggleReducer,
  initialValue as initialValueMenuToggle,
} from 'src/contexts/menu-toggle-context';
import {
  InitialLoaderContext,
  InitialLoaderDispatchContext,
  InitialLoaderReducer,
  initialValue as initialValueInitialLoader,
} from 'src/contexts/initial-loader-context';

type Props = {
  pathname: string;
  children: React.ReactNode;
};

export const ProviderManager: React.FC<Props> = ({
  pathname,
  children,
}: Props) => {
  const [menuToggleState, menuToggleDispatch] = React.useReducer(
    MenuToggleReducer,
    initialValueMenuToggle
  );
  const [initialLoaderState, initialLoaderDispatch] = React.useReducer(
    InitialLoaderReducer,
    initialValueInitialLoader
  );

  return (
    <ThemeProvider theme={styleTheme}>
      <PathnameContext.Provider value={{ pathname, isHome: pathname === '/' }}>
        <MenuToggleContext.Provider value={menuToggleState}>
          <MenuToggleDispatchContext.Provider
            value={{ dispatch: menuToggleDispatch }}
          >
            <InitialLoaderContext.Provider value={initialLoaderState}>
              <InitialLoaderDispatchContext.Provider
                value={{ dispatch: initialLoaderDispatch }}
              >
                {children}
              </InitialLoaderDispatchContext.Provider>
            </InitialLoaderContext.Provider>
          </MenuToggleDispatchContext.Provider>
        </MenuToggleContext.Provider>
      </PathnameContext.Provider>
    </ThemeProvider>
  );
};

export default ProviderManager;
