import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import { SEO } from 'src/components/common';
import { Entry } from 'src/components/database';
import { PageContextDatabaseType } from 'types/custom-types';

type Props = PageProps<
  GatsbyTypes.GetDatabaseSingleQuery,
  PageContextDatabaseType
>;

export const query = graphql`
  query GetDatabaseSingle($revTagId: String!) {
    product: file(
      relativeDirectory: { eq: "database/product" }
      name: { eq: $revTagId }
    ) {
      ...DatabaseSingleProduct
    }
    axonometricDrawing: file(
      relativeDirectory: { eq: "database/axonometric_drawing" }
      name: { eq: $revTagId }
    ) {
      ...DatabaseSingleAxonometricDrawing
    }
  }
`;

export const DatabaseSingle: React.FC<Props> = ({
  pageContext,
  data: { product, axonometricDrawing },
}: Props) => (
  <>
    <SEO title={pageContext?.revTagId} image={product?.publicURL ?? ''} />
    <Entry
      {...pageContext}
      product={product}
      axonometricDrawing={axonometricDrawing}
    />
  </>
);

export default DatabaseSingle;
