export const DEFAULT_MENUS = [
  {
    name: 'overview',
    label: 'overview',
    to: '/overview/',
  },
  {
    name: 'information',
    label: 'information',
    to: '/information/',
  },
  {
    name: 'contact',
    label: 'contact',
    to: 'mailto:info@vba2020.jp',
  },
];

export const MOBILE_DEFAULT_MENUS = [
  ...[
    {
      name: 'top',
      label: 'top',
      to: '/',
    },
  ],
  ...DEFAULT_MENUS,
];

export const MIRO_BOARD_ID = 'o9J_lL5EN-I=';
