import '@emotion/react';

export const theme = {
  font: {
    family: {
      site: {
        ja:
          'heisei-maru-gothic-std, "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO",  sans-serif',
        en:
          'acumin-pro, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, sans-serif',
        both:
          'acumin-pro, heisei-maru-gothic-std, "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO", sans-serif',
      },
    },
    size: {
      xxLarge: '2.1rem',
      xLarge: '1.7rem',
      large: '1.6rem',
      medium: '1.4rem',
      small: '1.3rem',
    },
  },
  // condensedRatio: 0.94,
  lineHeight: {
    ja: 2,
    en: 1.6,
  },
  colors: {
    bg: '#fff',
    font: '#000',
    blue: '#0000FF',
    green: '#00FF00',
    lightGray: '#EEEEEE',
  },
  structure: {
    site: {
      margin: {
        horizontal: {
          sp: 15,
        },
        top: {
          pc: 20,
        },
        bottom: {
          pc: 20,
        },
        left: {
          pc: 30,
        },
      },
    },
    entry: {
      margin: {
        right: {
          pc: 25,
        },
        bottom: {
          sp: 60,
          pc: 80,
        },
      },
    },
    header: {
      width: {
        pc: 395,
      },
      height: {
        pc: 295,
      },
    },
    title: {
      height: {
        sp: 65,
      },
    },
    menu: {
      height: {
        sp: 55,
      },
    },
    mobileDeadZone: {
      bottom: 43,
    },
  },
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    ipadProHorizontal: 1300,
    siteBreakpoint: 768,
    siteMaxWidth: 1200,
  },
  scrollOffset: {
    sp: 65, //title.height.sp
    pc: 295 + 20,
  },
};

type ThemeType = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeType {}
}

export default theme;
