import * as React from 'react';
import styled from '@emotion/styled';
import { Anchor } from 'src/components/common/atoms';

type Props = {
  className?: string;
};

export const Languages: React.VFC<Props> = ({ className }) => (
  <Wrapper className={className ?? ''}>
    <StyledAnchor to="#english">EN</StyledAnchor>
    <span aria-hidden={true}>/</span>
    <StyledAnchor to="#japanese">JA</StyledAnchor>
  </Wrapper>
);

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  pointer-events: auto;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`;

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxLarge};
`;

export default Languages;
