import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { usePathname } from 'src/utils';
import { ScreenReaderText } from 'src/components/common';
import { Anchor } from 'src/components/common/atoms';
import TitlePcSVG from 'src/images/title_pc.svg';
import TitleSpSVG from 'src/images/title_sp.svg';

type Props = {
  className?: string;
};

const query = graphql`
  query GetSiteTitme {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Title: React.VFC<Props> = ({ className }: Props) => {
  const { isHome } = usePathname();
  const { site } = useStaticQuery<GatsbyTypes.GetSiteTitmeQuery>(query);
  return (
    <Wrapper className={`${className || ''}`}>
      <Inner as={isHome ? 'h1' : 'p'}>
        <StyledAnchor to="/" lang="en">
          <ScreenReaderText>{site?.siteMetadata?.title}</ScreenReaderText>
          <TitleWrapper>
            <TitlePcSVG />
          </TitleWrapper>
          <TitleWrapperMobile>
            <TitleSpSVG />
          </TitleWrapperMobile>
        </StyledAnchor>
      </Inner>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  pointer-events: auto;
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Inner = styled.h1`
  font-weight: normal;
`;

const TitleWrapper = styled.span`
  display: none;
  ${media.greaterThanSiteBreakpoint} {
    display: block;
  }
`;

const TitleWrapperMobile = styled.span`
  display: none;
  ${media.siteBreakpointOrLess} {
    display: block;
  }
`;

const Wrapper = styled.div``;

export default Title;
