import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { media } from 'src/styles';
import Div100vh from 'react-div-100vh';
import {
  InitialLoaderContext,
  InitialLoaderDispatchContext,
} from 'src/contexts/initial-loader-context';
import { useIsMounted, useIsMobile } from 'src/utils';
import TitlePcSVG from 'src/images/title_pc.svg';

type Props = {
  className?: string;
};

const useIsHidden = () => {
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const { isHidden } = React.useContext(InitialLoaderContext);
  const { dispatch } = React.useContext(InitialLoaderDispatchContext);
  React.useEffect(() => {
    if (isMounted) {
      if (isMobile) {
        const timerID = setTimeout(() => {
          dispatch && dispatch({ type: 'set', payload: { isHidden: true } });
        }, 3000);
        return () => {
          clearTimeout(timerID);
        };
      } else {
        dispatch && dispatch({ type: 'set', payload: { isHidden: true } });
      }
    }
  }, [isMounted, isMobile, dispatch]);

  return isHidden;
};

export const Loader: React.VFC<Props> = ({ className }) => {
  const isHidden = useIsHidden();
  if (isHidden) {
    return null;
  }
  return (
    <Wrapper className={`${className ?? ''}`} aria-hidden={true}>
      <TitleWrapper>
        <TitlePcSVG />
      </TitleWrapper>
      <LoadingText>
        now loading
        <Point aria-hidden="true">.</Point>
        <Point aria-hidden="true">.</Point>
        <Point aria-hidden="true">.</Point>
        <Point aria-hidden="true">.</Point>
      </LoadingText>
    </Wrapper>
  );
};

const TitleWrapper = styled.div`
  width: 70%;
  max-width: 275px;
`;

const loading1 = keyframes`
  0%, 19% {
    opacity: 0;
  }
  20%, 100% {
    opacity: 1;
  }
`;

const loading2 = keyframes`
  0%, 39% {
    opacity: 0;
  }
  40%, 100% {
    opacity: 1;
  }
`;

const loading3 = keyframes`
  0%, 59% {
    opacity: 0;
  }
  60%, 100% {
    opacity: 1;
  }
`;

const loading4 = keyframes`
  0%, 79% {
    opacity: 0;
  }
  80%, 100% {
    opacity: 1;
  }
`;

const Point = styled.span`
  animation: 800ms infinite;
  &:nth-of-type(1) {
    animation-name: ${loading1};
  }
  &:nth-of-type(2) {
    animation-name: ${loading2};
  }
  &:nth-of-type(3) {
    animation-name: ${loading3};
  }
  &:nth-of-type(4) {
    animation-name: ${loading4};
  }
`;

const LoadingText = styled.p`
  color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  z-index: 1;
  bottom: ${({ theme }) => theme.structure.mobileDeadZone.bottom}px;
  right: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
`;

const hidden = keyframes`
  to {
    clip-path: inset(0 0 100% 0);
  }
`;

const Wrapper = styled(Div100vh)`
  position: relative;
  padding-left: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
  padding-right: ${({ theme }) => theme.structure.site.margin.horizontal.sp}px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* animation-name: ${hidden};
  animation-duration: 700ms;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
  clip-path: inset(0 0 0 0); */
  ${media.greaterThanSiteBreakpoint} {
    display: none;
  }
`;

export default Loader;
