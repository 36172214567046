import * as React from 'react';
import styled from '@emotion/styled';
import { media } from 'src/styles';
import { DEFAULT_MENUS } from 'src/constants';
import { MenuList } from '../menu-list';

type Props = {
  className?: string;
};

export const Navigation: React.VFC<Props> = ({ className }: Props) => (
  <Wrapper role="navigation" className={className ?? ''}>
    <MenuList menus={DEFAULT_MENUS} />
  </Wrapper>
);

const Wrapper = styled.nav`
  font-family: ${({ theme }) => theme.font.family.site.ja};
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  color: ${({ theme }) => theme.colors.blue};
`;

export default Navigation;
