import * as React from 'react';
import { graphql } from 'gatsby';
import { stripTags } from 'src/utils';
import { SEO as CommonSEO } from 'src/components/common';

type Props = GatsbyTypes.InformationSingleSeoFragment;

export const fragment = graphql`
  fragment InformationSingleSeo on MarkdownRemark {
    frontmatter {
      title
    }
    html
  }
`;

export const SEO: React.VFC<Props> = ({ frontmatter, html }) => (
  <CommonSEO
    title={frontmatter?.title}
    description={html ? stripTags(html) : undefined}
  />
);

export default SEO;
